















import Vue from "vue";

import FullEventList from "@/components/FullEventList.vue";

export default Vue.extend({
  name: "Events",
  components: {
    FullEventList,
  },
});
