








































import Vue from "vue";

import { getUrl } from "@/helpers";

export default Vue.extend({
  name: "FullEventList",
  props: {
    title: {
      type: String,
      default: "Eventos",
    },
  },
  beforeMount() {
    this.$store.dispatch("directorio/GetEvents", this.page);
  },
  mounted() {
    this.$redrawVueMasonry("masonry");
  },
  data() {
    return {
      loaded: 0,
      page: 1,
    };
  },
  computed: {
    events() {
      return this.$store.getters["directorio/events"];
    },
    totalImages() {
      return this.events.filter((e) => e.thumbnail_path).length;
    },
  },
  methods: {
    IncreaseLoadedEvents() {
      this.loaded++;
    },
    getUrl,
  },
  watch: {
    events() {
      this.$redrawVueMasonry("masonry");
    },
    loaded() {
      if (this.loaded === this.totalImages) {
        this.$redrawVueMasonry("masonry");
        setTimeout(() => this.$redrawVueMasonry("masonry"), 1000);
      }
    },
  },
});
